import React, { useState } from "react"
import Layout from "../components/layout"
import logo from "../images/Lancelot PianoB.png"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

const EveilMusical = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsEveilMusical {
        edges {
          node {
            id
            description
            title
            tarifsolo
            tarifduo
            desctarifduo
            desctarifsolo
          }
        }
      }
    }
  `)
  const [eveil] = useState(data.allDatoCmsEveilMusical.edges[0].node)
  return (
    <Layout logo={logo} color="#333" menu="Accueil" lien="/">
      <SEO title="Cours de Piano. Cours particuliers de piano à domicile sur Toulon et ses environs. Tout niveau, enfants-ados-adultes | Eveil Musical" />
      <EveilWrapper>
        <h1>{eveil.title}</h1>
        <ReactMarkdown source={eveil.description} />
        <h2>Tarif</h2>
        <CardsWrapper>
          <CardWrapper>
            <p>{eveil.tarifduo}</p>
            <span>{eveil.desctarifduo}</span>
          </CardWrapper>
        </CardsWrapper>
        <h3>
          Alors à vos claviers et rendez-vous sur le site pour prendre
          rendez-vous ou par téléphone au <br></br> 06 41 35 93 22 .
        </h3>
      </EveilWrapper>
    </Layout>
  )
}

const EveilWrapper = styled.div`
  width: 80%;
  margin: 3rem auto;

  h1 {
    text-align: center;
    margin-bottom: 3rem;
    font-family: "Roboto Condensed", sans-serif;
  }

  h2 {
    text-align: center;
    margin-top: 6rem;
    font-family: "Roboto Condensed", sans-serif;
  }

  h3 {
    margin-bottom: 5rem;
    text-align: center;
    line-height: 2.4rem;
    font-family: "Roboto Condensed", sans-serif;
  }
`
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 7rem;
  font-family: "Roboto Condensed", sans-serif;
`
const CardWrapper = styled.div`
  position: relative;
  width: 16rem;
  text-align: center;
  height: 20rem;
  padding: 2rem;
  margin-top: 3rem;
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02),
    0 2.2px 5.3px rgba(0, 0, 0, 0.028), 0 4.1px 10px rgba(0, 0, 0, 0.035),
    0 7.4px 17.9px rgba(0, 0, 0, 0.042), 0 13.8px 33.4px rgba(0, 0, 0, 0.05),
    0 33px 80px rgba(0, 0, 0, 0.07);

  p {
    display: block;
    font-size: 3rem;
    margin: 5rem 0;
  }
`

export default EveilMusical
